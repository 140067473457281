.app {
    padding: 20px;
    display: flex;
    flex-flow: wrap;
    align-items: center;
    justify-content: center;
    gap: 25px;
    width: 90%;
    height: 90%;
  }

  .title {
    flex-direction: column;
    flex-wrap: wrap;
    gap: 20px;
    width: 100%;
    text-align: center;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
  }

  .logo {
    height: 200px;
    width: 200px;
  }

  .appName {
    height: 50px;
    width: 50px;
    color: white;
  }

  .input-box {
    width: 60%;
    height: 100px;
    border-radius: 10px;
    background-color: whitesmoke;
    text-align: center;
  }

  .input-text {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 11pt;
    width: 100%;
    height: 100%;
    border-radius: 10px;
  }


  .picker {
    height: 40px;
    width: 60%;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    background-color: whitesmoke;
    font-size: 11pt;
    border-radius: 10px;
  }
  
  .output-box {
    margin-top: 20px;
    width: 60%;
    height: 300px;
    background-color: lightgray;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }