.button-enabled {
    background-color: #4CAF50; /* Green background */
    border: none; /* Remove border */
    color: white; /* White text */
    padding: 15px 32px; /* Some padding */
    text-align: center; /* Centered text */
    text-decoration: none; /* Remove underline */
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer; /* Mouse pointer on hover */
    border-radius: 12px; /* Rounded corners */
    transition-duration: 0.5s; /* Transition effect */
  }

  .button-disabled {
    background-color: #797979; /* Green background */
    border: none; /* Remove border */
    color: white; /* White text */
    padding: 15px 32px; /* Some padding */
    text-align: center; /* Centered text */
    text-decoration: none; /* Remove underline */
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer; /* Mouse pointer on hover */
    border-radius: 12px; /* Rounded corners */
    transition-duration: 0.5s; /* Transition effect */
  }
  
  .button-enabled:hover {
    background-color: #1b631f; /* Green background on hover */
  }

  .button-div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

  }
  